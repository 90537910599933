<template>
  <div>
    <vue-content-loading
      :weight="100"
      :height="200"
    >
      <rect x="1%" y="0" rx="10" ry="10" width="98%" height="100%" />
    </vue-content-loading>
  </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading'

export default {
  components: {
    VueContentLoading,
  },
}
</script>
