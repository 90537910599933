<template>
  <div class="ai-shelf-detection-upload-container root text-center">
    <h3 ref="title">แชะ&โชว์ รับพ้อยสุดฟิน</h3>
    <div v-if="!flagUpload" class="landing-container">
      <div class="py-4 mx-4 text-left" v-html="htmlContent" />
      <div>
        <div>
          <img
            class="m-1"
            v-for="(img, index) in imgList"
            :key="index"
            :src="img.url"
            width="160px"
            height="auto"
          />
        </div>
        <small class="font-weight-bold">ตัวอย่างรูปถ่าย</small>
      </div>
      <button id="start-event" class="mt-5 btn btn-primary" @click="toggle">
        <h5 class="mb-0">เริ่มกิจกรรม</h5>
      </button>
    </div>
    <div v-else class="upload-container">
      <div class="py-4">
        <UploadImage
          :imageUrl="image"
          :minSizeInBytes="2 * 1024 * 1024"
          :maxSizeInBytes="8 * 1024 * 1024"
          minAlertMessage="ขนาดไฟล์ต้อง ไม่ต่ำกว่า 2MB"
          maxAlertMessage="ขนาดไฟล์ ไม่ควรเกิน 8MB"
          errorImgSizeMessage="กรุณาตรวจสอบไฟล์ที่เลือกอีกครั้ง ความกว้างและความสูงของรูปขั้นต่ำ 1440x1920 พิกเซล หรือติดต่อเจ้าหน้าที่"
          v-on:result="
            (result) => {
              uploadData = result
            }
          "
        />
      </div>
      <button
        id="submit-event"
        class="mt-2 btn btn-primary"
        @click="submitAction"
        :disabled="validateImage"
      >
        <h5 class="mb-0">ตกลง</h5>
      </button>
    </div>
    <b-modal id="modal" hide-footer hide-header-close centered>
      <div class="d-block text-center">
        <img class="my-4" :src="fireworkImg" height="192px" width="192px" />
        <h4>คุณส่งรูปภาพสำเร็จ</h4>
        <div v-if="imgNo">เลขที่ {{ imgNo }}</div>
        <br />
        <div>
          คุณสามารถตรวจสอบการได้รับ<br />
          คะแนนในวันจันทร์ถัดไป
        </div>
      </div>
      <div class="d-block text-center mt-5">
        <button id="close-event" class="btn btn-primary" @click="closeAction">
          ปิด
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/uploadImage.vue'

import Firework from '@/assets/firework.png'

export default {
  name: 'ai-shelf-upload',
  components: {
    UploadImage,
  },
  computed: {
    validateImage() {
      const { uploadData } = this
      if (uploadData?.file?.size > 0) {
        return false
      }
      return true
    },
  },
  data() {
    return {
      htmlContent: `<div><center><b>เงื่อนไขการเข้าร่วมกิจกรรม</b></center><small>เงื่อนไขรูปถ่ายที่แนะนำ<br /> 1.1 ตัวอย่างรูปด้านขวามือ<br /> 1.2 file type : jpg<br /> 1.3 recommend file size : 1440x1920 ถึง 3024x4032 ขนาดรูปไม่ต่ำกว่า 2MB<br /> 1.4 การถ่ายรูป :<br /> <li>ต้องถ่ายรูปตู้แช่สินค้าให้มีความชัดเจน ไม่เบลอ และไม่มีสิ่งของบดบังสินค้า</li> <li>ต้องถ่ายรูปตู้แช่สินค้าโดยการเปิดตู้</li> <li>ไม่มีไอนํา/แสงสะท้อนที่กระจก</li> <li>ต้องถ่ายรูปตู้แช่สินค้าหน้าตรงเท่านั้น (ไม่เอียง)</li> <li>พยายามถือกล้องไว้ตรงกลางของตู้แช่</li> <li>หากตู้แช่มีมากกว่า 3 ชั้นให้ถ่ายทีละครึ่งตู้ (สมมติตู้มี 5 ชั้น ให้ถ่ายมา 2 รูป ประกอบด้วย รูป 3 ชั้น กับ รูป 2 ชั้น)</li> <li>หากเป็นตู้หลายบาน ให้ถ่ายทีละบาน</li></small></div>`,
      flagUpload: false,
      fireworkImg: Firework,
      imgList: [],
      image: null,
      uploadData: {},
      imgNo: null,
    }
  },
  methods: {
    async getWeeklyStatus() {
      window.loading(true)
      try {
        const res = await fetch(
          `${process.env.VUE_APP_API_URL}/ai-shelfs/transactions/current`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${this.$JWT}`,
            },
          }
        )
        window.loading(false)

        switch (res.status) {
          case 200:
            this.Swal.fire(
              '',
              'ร้านค้าส่งรูปภาพแล้ว ถ้าส่งรูปภาพใหม่จะทำการแทนที่รูปภาพเดิม',
              'warning'
            )
            break
          case 404:
            // Case Transaction not found
            break
          default:
            throw 'no case'
        }
      } catch (error) {
        console.log(error)
        window.loading(false)
      }
    },
    async getContent() {
      window.loading(true)
      try {
        const res = await fetch(
          `${process.env.VUE_APP_API_URL}/contents/available?slug=ai-shelf`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${this.$JWT}`,
            },
          }
        )
        window.loading(false)

        if (res.status != 200) {
          throw 'no case'
        }

        let body = []

        body = await res.json()

        const { content, contentAsset } = body

        this.htmlContent = content
        this.imgList = contentAsset
      } catch (error) {
        console.log(error)
        window.loading(false)
      }
    },
    async postUploadImg() {
      window.loading(true)
      try {
        const formData = new FormData()
        formData.append('slug', 'ai-shelf')
        if (this.uploadData?.file) {
          formData.append(
            'image',
            this.uploadData.file,
            this.uploadData.fileName
          )
        }
        const res = await fetch(
          `${process.env.VUE_APP_API_URL}/ai-shelfs/submit`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.$JWT}`,
            },
            body: formData,
          }
        )
        window.loading(false)

        let body = []

        body = await res.json()

        if (res.status != 200) {
          throw body
        }

        const { id } = body

        this.imgNo = id

        this.$bvModal.show('modal')
      } catch (error) {
        console.log(error)
        window.loading(false)
        this.Swal.fire(
          '',
          'กรุณาตรวจสอบไฟล์ที่เลือกอีกครั้ง หรือติดต่อเจ้าหน้าที่',
          'warning'
        )
      }
    },
    submitAction() {
      this.postUploadImg()
    },
    closeAction() {
      this.$bvModal.hide('modal')
      this.$router.push({ name: 'ai-shelf' })
    },
    toggle() {
      const { flagUpload } = this
      this.flagUpload = !flagUpload
      this.getWeeklyStatus()
    },
  },
  mounted() {
    window.loading(false)
    this.getContent()
  },
}
</script>

<style lang="scss">
$button-blue: #0048c0bf;
$border-blue: #03ffff;

#close-event {
  border-color: $border-blue;
  border-radius: 25px;
  background: $button-blue;
  width: 250px;
  height: 50px;
}

.main_content {
  height: 100vh !important;
}
</style>

<style lang="scss" scoped>
$button-blue: #0048c0bf;
$border-blue: #03ffff;

$button-white: #e0e0e0;
$border-gray: #979797;

.root {
  background: url('~@/assets/bg_ai.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  overflow: scroll;
  color: white;
  font-size: 16px;
  &.ai-shelf-detection-upload-container {
    padding: 20px 0;
  }
  #start-event,
  #submit-event {
    border-color: $border-blue;
    border-radius: 25px;
    background: $button-blue;
    width: 250px;
    height: 50px;
    &:disabled {
      border-color: $border-gray;
      background: $button-white;
    }
  }
  h3 {
    text-shadow: 3px 3px 0px #3066ed, 3px -3px 0px #3066ed, -3px 3px 0px #3066ed,
      -3px -3px 0px #3066ed, 3px 0px 0px #3066ed, 0px 3px 0px #3066ed,
      -3px 0px 0px #3066ed, 0px -3px 0px #3066ed;
  }
  .upload-container {
    padding: 0px 20px 10px;
  }
}
</style>
