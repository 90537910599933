<template>
  <div class="upload-image">
    <label for="upload-photo" class="upload-input">
      <div v-if="isLoading"><ImageLoading /></div>
      <div
        v-else-if="previewImg !== null"
        class="d-flex justify-content-center align-items-center position-relative"
      >
        <img id="blankImg" :src="boxImg" width="100%" height="240px" />
        <img
          class="position-absolute"
          v-show="previewImg"
          id="previewImg"
          width="75%"
          height="200px"
          :src="previewImg"
        />
      </div>
      <div
        v-else
        class="d-flex justify-content-center align-items-center position-relative"
      >
        <img id="blankImg" :src="boxImg" width="100%" height="240px" />
        <img
          class="position-absolute"
          :src="blankImg"
          width="70px"
          height="70px"
        />
      </div>
    </label>
    <input
      type="file"
      name="photo"
      ref="fileInput"
      :capture="getMobileOperatingSystem() == 'iOS' ? false : true"
      :accept="accept"
      @change="fileChange"
      id="upload-photo"
    />
  </div>
</template>

<script>
import Compressor from 'compressorjs'

import BoxImage from '@/assets/box_ai.png'
import AddImage from '@/assets/add-image.svg'

import ImageLoading from '@/components/ImageLoading.vue'

export default {
  components: {
    ImageLoading,
  },
  props: {
    imageUrl: {
      type: String,
    },
    accept: {
      type: String,
      default: 'image/jpg, image/jpeg',
    },
    previewWidth: {
      type: Number,
      default: 1440,
    },
    previewHeight: {
      type: Number,
      default: 1920,
    },
    minWidth: {
      type: Number,
      default: 1440,
    },
    minHeight: {
      type: Number,
      default: 1920,
    },
    minSizeInBytes: {
      type: Number,
      // example 2 * 1024 * 1024 // 2 MB in bytes
    },
    maxSizeInBytes: {
      type: Number,
      // example 8 * 1024 * 1024 // 8 MB in bytes
    },
    minAlertMessage: {
      type: String,
    },
    maxAlertMessage: {
      type: String,
    },
    errorImgSizeMessage: {
      type: String,
    },
  },
  data() {
    return {
      previewImg: this.imageUrl || null,
      isLoading: false, // loading
      blankImg: AddImage,
      boxImg: BoxImage,
    }
  },
  watch: {
    imageUrl(val) {
      this.previewImg = val
    },
  },
  methods: {
    async fileChange(e) {
      const { errorImgSizeMessage, minHeight, minWidth } = this
      const file = e.target.files[0]

      if (file) {
        const img = new Image()
        img.src = URL.createObjectURL(file)

        img.onload = () => {
          if (img.width > minWidth && img.height > minHeight) {
            this.checkImgSize(file)
            return
          }
          this.Swal.fire('', errorImgSizeMessage, 'warning')
        }
      }
    },
    checkImgSize(file) {
      const {
        minSizeInBytes,
        minAlertMessage,
        maxSizeInBytes,
        maxAlertMessage,
      } = this

      this.isLoading = true

      if (minSizeInBytes && file.size < minSizeInBytes) {
        this.Swal.fire('', minAlertMessage, 'warning')
        this.isLoading = false
        return
      }

      if (maxSizeInBytes && file.size > maxSizeInBytes) {
        this.Swal.fire('', maxAlertMessage, 'warning')
        this.isLoading = false
        return
      }

      this.resizeImage(file)
    },
    resizeImage(file) {
      new Compressor(file, {
        quality: 0.6,
        convertSize: 0,
        maxWidth: this.previewWidth,
        maxHeight: this.previewHeight,
        success: (result) => {
          // console.log(result)
          this.preview(result)
          this.isLoading = false
          this.$emit('result', { file: file, fileName: file.name })
        },
        error: (err) => {
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          )
          this.isLoading = false
          if (isSafari) {
            const img = new Image()
            img.src = URL.createObjectURL(file)
            this.previewImg = img.src
            this.$emit('result', { file: file, fileName: file.name })
          } else {
            console.log(err.message)
            this.Swal.fire(
              '',
              'กรุณาตรวจสอบไฟล์ที่เลือกอีกครั้ง หรือติดต่อเจ้าหน้าที่',
              'warning'
            )
          }
        },
      })
    },
    preview(file) {
      const fr = new FileReader()
      fr.readAsDataURL(file)
      fr.onload = (e) => {
        this.previewImg = e.target.result
      }
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }

      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }

      return 'unknown'
    },
  },
}
</script>

<style lang="scss">
.upload-image {
  border-radius: 25px;
  label {
    cursor: pointer;
    margin: 0;
  }
  #blankImg {
    border-radius: 25px;
  }
  #upload-photo {
    opacity: 0;
    position: absolute;
    display: none;
    z-index: -1;
  }
  .upload-input {
    width: 100%;
  }
}
</style>
